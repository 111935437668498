import http from "@/utils/http";
//
/**
 *  消息提示
 */

// 未读消息
export function messageApiUnRead(params) {
    return http.get(`/v1/admin/message/unread-count`, params);
}

// 全部已读
export function messageApiReadAll(params) {
    return http.post(`/v1/admin/message/all-read`, params);
}
