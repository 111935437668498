import {
  createStore
} from 'vuex'
import persistedState from 'vuex-persistedstate'
export default createStore({
  state: {
    token: '', //储存token
    role: '',
    pageState: {},
    pageStateD: {},
    dealerCode: '',
    dealerId:'',
    authority:[],
    areaCode:[],
    LuckyDraw:[],
    openBox:''
  },
  getters: {},
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setRole(state, role) {
      state.role = role
    },
    setPageState(state, pageState) {
      state.pageState = pageState
    },
    setPageStateD(state, pageStateD) {
      state.pageStateD = pageStateD
    },
    setDealerUserInfo(state, dealerUserInfo) {
      state.dealerCode = dealerUserInfo
    },
    setDealerId(state, dealerId){
      state.dealerId = dealerId
    },
    setAuthority(state, authority) {
      state.authority = authority
    },
    setAreaCode(state, areaCode) {
      state.areaCode = areaCode
    },
    setOpenBox(state, openBox){ 
      state.openBox = openBox
    },
    setLuckyDraw(state, LuckyDraw){
      state.LuckyDraw = LuckyDraw
    }
  },
  actions: {},
  modules: {},
  plugins: [persistedState({
    paths: ['token', 'role', 'dealerCode','dealerId','authority','openBox','LuckyDraw'],
  })]
})