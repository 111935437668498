import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import IndexView from '../views/IndexView.vue'
import store from '@/store/index'
// const store = useStore()

const routes = [{
    path: "/notFound", //404
    name: "notFound",
    component: () => import("@/views/notFound.vue"),
  },
  {
    path: "/login", //登录页面
    name: "login",
    component: () => import("@/views/login/loginIndex.vue"),
  }, {
    path: '/',
    name: 'home',
    component: IndexView,
    children: [{
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/distributorView',
        name: 'distributorView',
        component: () => import('../views/distributorView.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/terminalMember',
        name: 'terminalMember',
        // meta: {
        //   keepAlive: true    //true为需要缓存，false为不需要缓存（不需要的也可以不加）
        // },
        component: () => import('../views/terminalMember.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/terminalMember/checkView',
        name: 'checkView',
        component: () => import('../components/terminalMemberC/checkView.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/terminalMember/editView',
        name: 'editView',
        component: () => import('../components/terminalMemberC/editView.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/distributorView/checkView',
        name: 'distributorCheckView',
        component: () => import('../components/distributorViewC/checkView.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/distributorView/editView',
        name: 'distributorEditView',
        component: () => import('../components/distributorViewC/editView.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/floatList',
        name: 'floatList',
        component: () => import('../views/floatList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/pointsRecord',
        name: 'pointsRecord',
        component: () => import('../views/pointsRecord.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/carnivalRecord',
        name: 'carnivalRecord',
        component: () => import('../views/carnivalRecord.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/couponList',
        name: 'couponList',
        component: () => import('../views/couponList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/couponList/DistributionList',
        name: 'DistributionList',
        component: () => import('../components/couponListC/DistributionList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/couponList/DistributionListAll',
        name: 'DistributionListAll',
        component: () => import('../components/couponListC/DistributionListAll.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/couponList/CoupondistributionList',
        name: 'CoupondistributionList',
        component: () => import('../components/couponListC/CoupondistributionList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/marketingManage',
        name: 'marketingManage',
        component: () => import('../views/MarketingManage.vue'),
        meta: {
          auth: true
        }
      },

      {
        path: '/marketingManage/addActivity',
        name: 'marketingManageAddActivity',
        component: () => import('../components/MarketingManageC/activity/addActivity.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/marketingManage/winningRecord',
        name: 'winningRecord',
        component: () => import('../components/MarketingManageC/activity/winningRecord.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/marketingManage/dealerContest',
        name: 'dealerContest',
        component: () => import('../components/MarketingManageC/activity/dealerContest.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/marketingManage/signName',
        name: 'marketingManage/signName',
        component: () => import('../components/MarketingManageC/activity/signName.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/marketingManage/contestant',
        name: 'contestant',
        component: () => import('../components/MarketingManageC/activity/contestantVue.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/MarketingManage/dayRecord',
        name: 'dayRecord',
        component: () => import('../components/MarketingManageC/saleFix/dayRecord.vue'),
        meta: {
          auth: true
        }
      },


      // 积分商城路由
      {
        path: '/mall',
        name: 'mall',
        component: () => import('../views/mallView.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/mallShopList',
        name: 'mallShopList',
        component: () => import('../views/mallShopsList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/mallShopGroup',
        name: 'mallShopGroup',
        component: () => import('../views/mallShopGroup.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/addMallShopGroup',
        name: 'addMallShopGroup',
        component: () => import('../views/addMallShopGroup.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/mallCategory',
        name: 'mallCategory',
        component: () => import('../views/mallCategory.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/addMallCategory',
        name: 'addMallCategory',
        component: () => import('../views/addMallCategory.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/EditMallCategory',
        name: 'EditMallCategory',
        component: () => import('../views/EditMallCategory.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/mallShopDetail',
        name: 'mallShopDetail',
        component: () => import('../views/mallShopDetail.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/mallOrder',
        name: 'mallOrder',
        component: () => import('../views/mallOrder.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/mallOrderList',
        name: 'mallOrderList',
        component: () => import('../views/mallOrderList.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/mallOrderDetail',
        name: 'mallOrderDetail',
        component: () => import('../views/mallOrderDetail.vue'),
        meta: {
          auth: true
        }
      },
      //memberLevel
      {
        path: '/memberLevel',
        name: 'memberLevel',
        component: () => import('../views/memberLevel.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/memberRank',
        name: 'memberRank',
        component: () => import('../views/memberRank.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/memberRankEdit',
        name: 'memberRankEdit',
        component: () => import('../views/memberRankEdit.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/membershipLevel',
        name: 'membershipLevel',
        component: () => import('../views/membershipLevel.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/membershipLevelEdit',
        name: 'membershipLevelEdit',
        component: () => import('../views/membershipLevelEdit.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/memberList',
        name: 'memberList',
        component: () => import('../views/memberList.vue'),
        meta: {
          auth: true
        }
      }, {
        path: '/memberList/memberOrder',
        name: 'memberOrder',
        component: () => import('../components/memberListC/memberOrder.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/memberList/memberDetails',
        name: 'memberDetails',
        component: () => import('../components/memberListC/memberDetails.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/productSettings/productSettingsDetails',
        name: 'productSettingsDetails',
        component: () => import('../components/productSettingsC/productSettingsDetails.vue'),
        meta: {
          auth: true
        }
      },
      // {
      //   path: '/mallSaleDetail',
      //   name: 'mallSaleDetail',
      //   component: () => import('../views/mallSaleDetail.vue'),
      //   meta: {
      //     auth: true
      //   }
      // },
      {
        path: '/productSettings',
        name: 'productSettings',
        component: () => import('../views/productSettings.vue'),
        meta: {
          auth: true
        }
      }
      // 
      , {
        path: '/MarketingManage/inviteGift',
        name: 'inviteGift',
        component: () => import('../components/MarketingManageC/saleFix/inviteGift.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/pointsKing',
        name: 'pointsKing',
        component: () => import('../views/pointsKing.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/growTree',
        name: 'growTree',
        component: () => import('../views/growTree.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/goodsList',
        name: 'goodsList',
        component: () => import('../views/goodsList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/vipList',
        name: 'vipList',
        component: () => import('../views/vipList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/growTree/rewardAllocationEdit',
        name: 'rewardAllocationEdit',
        component: () => import('../components/growTreeC/rewardAllocationEdit.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/growTree/taskAllocationEdit',
        name: 'taskAllocationEdit',
        component: () => import('../components/growTreeC/taskAllocationEdit.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/pointsKing/addActivity',
        name: 'addActivity',
        component: () => import('../components/pointsKingC/addActivity.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/pointsKing/rangList',
        name: 'rangList',
        component: () => import('../components/pointsKingC/rangList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/pointsKing/signName',
        name: 'signName',
        component: () => import('../components/pointsKingC/signName.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/productIntroduction',
        name: 'productIntroduction',
        component: () => import('../views/productIntroduction.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/productIntroduction/addProduct',
        name: 'addProduct',
        component: () => import('../components/productIntroductionC/addProduct.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/productIntroduction/drugScheme',
        name: 'drugScheme',
        component: () => import('../components/productIntroductionC/drugScheme.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/productIntroduction/drugScheme/addDrugScheme',
        name: 'addDrugScheme',
        component: () => import('../components/productIntroductionC/drugSchemeC/addDrugScheme.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/academicPromotion',
        name: 'academicPromotion',
        component: () => import('../views/academicPromotion.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/academicPromotion/addAcademic',
        name: 'addAcademic',
        component: () => import('../components/academicPromotionC/addAcademic.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/discoverView',
        name: 'discoverView',
        component: () => import('../views/discoverView.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/discoverView/addText',
        name: 'addText',
        component: () => import('../components/discoverViewC/addText.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/discoverView/addTextDealer',
        name: 'addTextDealer',
        component: () => import('../components/discoverViewC/addTextDealer.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/hotItem',
        name: 'hotItem',
        component: () => import('../views/hotItem.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/hotItem/addHotItem',
        name: 'addHotItem',
        component: () => import('../components/hotItemC/addHotItem.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/recordRule',
        name: 'recordRule',
        component: () => import('../views/recordRule.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/recordRule/addRecordRule',
        name: 'addRecordRule',
        component: () => import('../components/recordRuleC/addRecordRule.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/memberLevelDescription',
        name: 'memberLevelDescription',
        component: () => import('../views/memberLevelDescription.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/memberLevelDescription/addMemberLevelDescription',
        name: 'addMemberLevelDescription',
        component: () => import('../components/memberLevelDescriptionC/addMemberLevelDescription.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/feedbackView',
        name: 'feedbackView',
        component: () => import('../views/feedbackView.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/accountList',
        name: 'accountList',
        component: () => import('../views/accountList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/accountList/addAccountList',
        name: 'addAccountList',
        component: () => import('../components/accountListC/addAccountList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/customerService',
        name: 'customerService',
        component: () => import('../views/customerService.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/customerService/addCustomerService',
        name: 'addCustomerService',
        component: () => import('../components/customerServiceC/addCustomerService.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/pointsRuleConfiguration',
        name: 'pointsRuleConfiguration',
        component: () => import('../views/pointsRuleConfiguration.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/pointsRuleConfiguration/addPointsRuleConfiguration',
        name: 'addPointsRuleConfiguration',
        component: () => import('../components/pointsRuleConfigurationC/addPointsRuleConfiguration.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/sellingPriceConfiguration',
        name: 'sellingPriceConfiguration',
        component: () => import('../views/sellingPriceConfiguration.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/sellingPriceConfiguration/addSellingPriceConfigurationC',
        name: 'addSellingPriceConfigurationC',
        component: () => import('../components/sellingPriceConfigurationC/addSellingPriceConfigurationC.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/varietyConfiguration',
        name: 'varietyConfiguration',
        component: () => import('../views/varietyConfiguration.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/varietyConfiguration/addVarietyConfiguration',
        name: 'addVarietyConfiguration',
        component: () => import('../components/varietyConfigurationC/addVarietyConfiguration.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/basicConfiguration',
        name: 'basicConfiguration',
        component: () => import('../views/basicConfiguration.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/extensionList',
        name: 'extensionList',
        component: () => import('../views/extensionList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/extensionList/addExtensionListC',
        name: 'addExtensionListC',
        component: () => import('../components/extensionListC/addExtensionListC.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/personalInfo',
        name: 'personalInfo',
        component: () => import('../views/personalInfo.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/accountInfo',
        name: 'accountInfo',
        component: () => import('../views/accountInfo.vue'),
        meta: {
          auth: true
        }
      }
      // 经销商路由
      ,
      {
        path: '/dealerHome',
        name: 'dealerHome',
        component: () => import('../views/dealerHome.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/hotActivity',
        name: 'hotActivity',
        component: () => import('../views/hotActivity.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/dealerTerminalMember',
        name: 'dealerTerminalMember',
        component: () => import('../views/dealerTerminalMember.vue'),
        meta: {
          auth: true
        }
      },

      {
        path: '/dealerFloatList',
        name: 'dealerFloatList',
        component: () => import('../views/dealerFloatList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/dealerCoupon',
        name: 'dealerCoupon',
        component: () => import('../views/dealerCoupon.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/dealerAuthenticationGift',
        name: 'dealerAuthenticationGift',
        component: () => import('../views/dealerAuthenticationGift.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/dealerTerminalSaleList',
        name: 'dealerTerminalSaleList',
        component: () => import('../views/dealerTerminalSaleList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/dealerTerminalSaleList/CheckDealerTerminalSaleList',
        name: 'CheckDealerTerminalSaleList',
        component: () => import('@/components/dealerTerminalSaleListC/CheckDealerTerminalSaleList.vue'),
        meta: {
          auth: true
        }
      },
      {
        path: '/employeePromotion', //经销商端-员工推广 页面
        name: 'employeePromotion',
        component: () => import('../views/employeePromotion.vue'),
        meta: {
          auth: true
        }
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {

  const token = store.state.token
  if (to.matched.length === 0) {
    next('/notFound')
  } else {
    if (to.meta.auth) {
      if (token) {
        next();
      } else {
        next('/login')
      }
    } else {
      next();
    }
  }


})

export default router