// import {
//     ref,
//     watch
// } from 'vue'
// import store from '../store/index'

const admin = [{
        name: "系统首页",
        id: "1",
        path: "/"
    },
    {
        name: "终端管理",
        id: "2",
        child: [{
                name: "终端会员",
                id: "2-1",
                path: "/terminalMember"
            },
            {
                name: "经销商",
                id: "2-2",
                path: '/distributorView',
            }
        ],
    },
    {
        name: "业务管理",
        id: "3",
        child: [{
                name: "流向列表",
                id: "3-1",
                path: '/floatList'
            },
            {
                name: "积分记录",
                id: "3-2",
                path: '/pointsRecord'
            },
            {
                name: "优惠券列表",
                id: "3-3",
                path: '/couponList'
            },
            {
                name: "营销活动管理",
                id: "3-4",
                path: '/marketingManage',
            },
            {
                name: "积分王",
                path: '/pointsKing',
                id: "3-5",
            },
            {
                name: "成长树",
                path: '/growTree',
                id: "3-6",
            },
        ],
    },
    {
        name: "积分商城",
        id: "9",
        child: [
            {
                name: "商品管理",
                id: "9-2",
                path: '/mallShopList',
            },
            {
                name: '商品订单',
                id: "9-5",
                path: '/mallOrder',
            }
        ],
    },
]

export const menuOther = [
    ...admin
]