/****   request.js   ****/
// 导入axios
import axios from "axios";
import store from "@/store";
// 使用element-ui message做消息提醒
import {
  message
} from 'ant-design-vue';
import router from "@/router/index";

// // 不能设置为 true
// axios.defaults.withCredentials = false;
// 	// 定义代理服务器的主机名和端口
// 	axios.defaults.proxy = {
//     host: 'http://localhost',
//     port: 5000,
// };

// import { getToken } from "@/utils/auth";
export const baseURL = "https://da-api.joincare.com.cn/";  // 正式
//  export const baseURL = "http://192.168.0.128:9501/"; // 测试
//1. 创建新的axios实例，
const service = axios.create({
  // 公共接口
  // baseURL: "http://47.108.31.34:9501", //测试
  baseURL: baseURL, //正式
  // 超时时间 单位是ms，这里设置了3s的超时时间
  // timeout: 30 * 1000
});
// /* // 2.请求拦截器
// http request 拦截器
// service.interceptors.request.use(
//   config => {
//     if (getToken()) {
//       //判断token是否存在
//       config.headers.Authorization = getToken(); //将token设置成请求头
//     }
//     return config;
//   },
//   err => {
//     return Promise.reject(err);
//   }

// );


service.interceptors.request.use(
  config => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    // config.headers = {
    //   "Content-Type": "application/json;charset=UTF-8" //配置请求头
    // };
    //注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
    // config.headers.Authorization = `token eyJ0eXAiOiJqd3QifQ.eyJzdWIiOiIxIiwiaXNzIjoiaHR0cDpcL1wvOiIsImV4cCI6MTY4MjEyNzQ3MCwiaWF0IjoxNjgyMDQxMDcwLCJuYmYiOjE2ODIwNDEwNzAsInVpZCI6MSwicyI6IlhIVk12SCIsImp0aSI6ImU2YmY4YWEwMTBkMWEyZTI2NzEyODEzNjE0ZTFmNjA4In0.Y2JmZGU2ZjllZTBhYjAxN2ZjNTk5Y2FhYWY2MzhjY2UzMWM0NmU3OQ`;
    if (config.url == '/v1/web/user/login') {
      console.log('');
    } else {
      if (store.state.token) {
        config.headers.Authorization = `Bearer ${store.state.token}`;
      } else {
        message.error('请先登录');
      }
    }

    // config.headers.Authorization=getToken()
    return config;

    // const token = getCookie("名称"); //这里取token之前，你肯定需要先拿到token,存一下

    // if (token) {
    //   config.params = { token: token }; //如果要求携带在参数中
    //   config.headers.token = token; //如果要求携带在请求头中
    // }
    // return config;
  },
  // error => {
  //   Promise.reject(error);
  // }
);
//  */
// 3.响应拦截器
service.interceptors.response.use(
  response => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等

    let res = response.data; // 取到响应返回的数据
    if (res.code == 200) {
      // 可能需要返回一些操作成功，删除成功之类的提示，所以把整个信息返回，回调中取得时候记得加一层data
      return res;
    } else {
      if (res.code == '403') {
        router.push('/login')
        message.error('登录超时！');
      } else if(res.code == '404'){
        router.push('/notFound')
        // 状态码如果不是200则为报错，弹出报错信息，且不返回任何数据
        // eslint-disable-next-line no-irregular-whitespace
        // return Promise.reject(new Error(res || 'Error'))
      }else{
        return Promise.reject(res.msg ? res.msg : '连接错误，请刷新当前页面！');
      }
    }
    /*  return response*/
  },
  // error => {
  //   /***** 接收到异常响应的处理开始 *****/
  //   // 在响应失败时执行的逻辑

  //   if (error && error.response) {
  //     // 1.公共错误处理
  //     // 2.根据响应码具体处理
  //     switch (error.response.status) {
  //       // case 400:
  //       //   error.message = "错误请求";
  //       //   break;
  //       // case 401:
  //       //   error.message = "未授权，请重新登录";
  //       //   break;
  //       // case 403:
  //       //   error.message = "拒绝访问";
  //       //   break;
  //       case 404:
  //         error.message = "请求错误,未找到该资源";
  //         router.push('/notFound')
  //         break;
  //       // case 405:
  //       //   error.message = "请求方法未允许";
  //       //   break;
  //       // case 408:
  //       //   error.message = "请求超时";
  //       //   break;
  //       // case 500:
  //       //   error.message = "系统维护中，请稍后再试";
  //         // break;
  //       // case 501:
  //       //   error.message = "网络未实现";
  //       //   break;
  //       // case 502:
  //       //   error.message = "网络错误";
  //       //   break;
  //       // case 503:
  //       //   error.message = "服务不可用";
  //       //   break;
  //       // case 504:
  //       //   error.message = "网络超时";
  //       //   break;
  //       // case 505:
  //       //   error.message = "http版本不支持该请求";
  //       //   break;
  //       default:
  //         error.message = `连接错误${error.response.status}`;
  //         break;
  //     }
  //     message.error(error.response.msg ? error.response.msg : error.message)
  //   } 
  //   else {
  //     // 超时处理
  //     // message.error(error.message ? error.message : "连接服务器失败!")
  //     if (JSON.stringify(error).includes("timeout")) {
  //       message.error("服务器响应超时，请刷新当前页");
  //     }
  //   }
    
  //   /***** 处理结束 *****/
  //   //如果不需要错误处理，以上的处理过程都可省略
  //   return Promise.resolve(error.response);
  // }
);
//4.导入文件
export default service;