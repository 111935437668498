import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "amfe-flexible";
// 引入e-charts 全局使用
import * as echarts from "echarts";
// 引用ant组件
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
// 完整加载  转盘
import VueLuckyCanvas from '@lucky-canvas/vue'
//转盘
// 可拖动指令


const app = createApp(App).use(store).use(router).use(Antd).use(VueLuckyCanvas);
app.config.globalProperties.$echarts = echarts;
app.directive('draggable', {
    mounted(el) {
        let isDragging = false;
        let startPosX = 0;
        let startPosY = 0;

        el.addEventListener('mousedown', (event) => {
            isDragging = true;
            startPosX = event.clientX;
            startPosY = event.clientY;
        });

        document.addEventListener('mousemove', (event) => {
            if (isDragging) {
                const offsetX = event.clientX - startPosX;
                const offsetY = event.clientY - startPosY;
                startPosX = event.clientX;
                startPosY = event.clientY;
                el.style.left = parseInt(el.style.left || 0) + offsetX + 'px';
                el.style.top = parseInt(el.style.top || 0) + offsetY + 'px';
            }
        });

        document.addEventListener('mouseup', () => {
            isDragging = false;
        });
    }
});
app.mount('#app');