// import {
//     ref,
//     watch
// } from 'vue'
// import store from '../store/index'

const dealer = [{
        name: "系统首页",
        id: "1",
        path: '/dealerHome'
    },
    // {
    //     name: "热门活动",
    //     id: "6",
    //     path: '/hotActivity'
    // },
    {
        name: "终端管理",
        id: "7",
        child: [{
            name: "终端会员",
            id: "7-1",
            path: '/dealerTerminalMember'
        }],
    },
    {
        name: "业务管理",
        id: "8",
        child: [{
                name: "流向列表",
                id: "8-1",
                path: '/dealerFloatList'
            },
            {
                name: "优惠券核销",
                id: "8-2",
                path: '/dealerCoupon'
            },
            {
                name: "认证礼",
                id: "8-3",
                path: '/dealerAuthenticationGift'
            },
            {
                name: "终端销售列表",
                id: "8-4",
                path: '/dealerTerminalSaleList'
            },
            {
                name: "员工推广",
                id: "8-5",
                path: '/employeePromotion'
            },
        ],
    }
]
// const menuData = ref([])

// watch(() => store.state.role, (val) => {
//     console.log('-----val', val);
//     if (val == '1') {
//         console.log('-----admin', val);
//         menuData.value = []
//         menuData.value = admin
//     } else {
//         console.log('-----dealer', val);
//         menuData.value = []
//         menuData.value = dealer
//     }
// }, {
//     immediate: true
// })
// console.log('menuData.value-----',menuData.value);
export const menuDealer = [
    ...dealer
]