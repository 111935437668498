<template>
  <!-- <router-link to="/">Home</router-link> |
  <router-link to="/about">About</router-link> -->
  <a-config-provider :locale="zhCN">
    <router-view />
  </a-config-provider>
</template>
<script setup>
import zhCN from "ant-design-vue/es/locale/zh_CN";//全局设置ant框架语言为中文
import { message, notification } from 'ant-design-vue';
import { h, onMounted,watch } from "vue";
import { useRouter } from "vue-router";
import { messageApiUnRead, messageApiReadAll } from '@/api/messageApi/messageApi'
import { useStore } from "vuex";
const store = useStore()
const router = useRouter()
onMounted(() => {
  if (store.state.token && store.state.role == 1 ) {
    const key = `open${Date.now()}`;
    messageApiUnRead({ tag: '11' }).then(res => {
      if (Number(res?.data) > 0) {
        watch(()=>store.state.authority,() => {
          if (!store.state.authority.includes('管理员')) return
          notification.open({
            message: '消息通知',
            description: `你有${res?.data}条未读消息`,
            btn: () => h('div', { id: 'btn-wrap' }, [
              h('div', {
                id: 'readBtn', onClick: () => {
                  notification.close(key)
                  messageApiReadAll({ tag: '11' }).then(res => {
                    if (res.code == 200) {
                      message.success('全部已读成功')
                    } else {
                      message.success('全部已读失败')
                    }
                  })
                }
              }, '全部已读'),
              h('div', {
                id: 'checkBtn', onClick: () => {
                  router.push('/feedbackView')
                  notification.close(key)
                }
              }, '立即查看')
            ]),
            key
          });
        }, {immediate:true})
      }
    }).catch()
  }


})


</script>
<style lang="scss">
#app {
  font-family: PingFangSC-Medium, PingFang SC, 思源黑体;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@media screen and (max-width: 1360px) {

  // 限制适应rem的最小宽度为1200px
  html {
    font-size: 134.3px !important;
    min-width: 1360px;
  }
}

@media screen and (max-height: 650px) {

  html {
    // font-size: 134.3px !important;
    min-height: 650px;
  }
}

#readBtn {
  width: 60px;
  height: 26px;
  background: #1890FF;
  border-radius: 3px;
  color: white;
  text-align: center;
  line-height: 26px;
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
}

#readBtn:hover {
  background: #4ba1f1;
}

#checkBtn {
  cursor: pointer;
  width: 60px;
  height: 26px;
  border: 1px solid transparent;
  box-shadow: 0 2px #00000004;
  color: #000000d9;
  border-color: #d9d9d9;
  // background: #1890FF;
  border-radius: 3px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
}

#checkBtn:hover {
  cursor: pointer;
  width: 60px;
  height: 26px;
  border: 1px solid transparent;
  box-shadow: 0 2px #00000004;
  color: #1890FF;
  border-color: #1890FF;
  border-radius: 3px;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
}

#btn-wrap {
  width: 120px;
  display: flex;
  justify-content: space-between;
}

html,
body,
#app {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  // font-size: 14px;
}
</style>
