<template>
  <div class="home-page" id="index-view">
    <a-layout class="h-cont" v-if="true">
      <a-layout-header class="flex-between" theme="dark">
        <div class="flex-left">
          <div class="logo">
            <img src="../assets/logo.png" alt="" />
          </div>
        </div>
        <div class="flex-right">
          <div class="flex-left" v-if="store.state.role == 1 && arr.includes('管理员')" @click="toMessage" style="cursor:pointer">
            <div class="hch-text m-r-10">消息</div>
            <a-badge :dot="showPot">
              <bell-filled class="hchr-icon" />
            </a-badge>
          </div>
          <div class="flex-left m-l-40">
            <div class="hch-icon">
              <a-avatar :src="defaultAvatar"> </a-avatar>
            </div>
            <div class="hch-text m-l-10">
              <a-dropdown placement="bottom">
                <div class="flex-left">
                  <span class="m-r-6">{{ time + ',' + name }}</span>
                  <CaretDownOutlined />
                </div>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="changePass">
                      修改密码
                    </a-menu-item>
                    <a-menu-item @click="personal">
                      个人中心
                    </a-menu-item>
                    <a-menu-item @click="quit">
                      退出登录
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <a-modal v-model:visible="visible" title="修改密码" :footer="null">
                <a-form ref="formRef" :model="formState" @finish="onFinish" :rules="rules" @finishFailed="onFinishFailed">
                  <a-form-item name="oldPassword">
                    <a-input-password v-model:value="formState.oldPassword" placeholder="请输入原密码">
                      <template #prefix>
                        <LockOutlined class="site-form-item-icon" />
                      </template>
                    </a-input-password>
                  </a-form-item>
                  <a-form-item name="newPassword">
                    <a-input-password v-model:value="formState.newPassword" placeholder="请输入新密码">
                      <template #prefix>
                        <LockOutlined class="site-form-item-icon" />
                      </template>
                    </a-input-password>
                  </a-form-item>
                  <a-form-item name="checkNewPassword">
                    <a-input-password v-model:value="formState.checkNewPassword" placeholder="请再次输入新密码">
                      <template #prefix>
                        <LockOutlined class="site-form-item-icon" />
                      </template>
                    </a-input-password>
                  </a-form-item>
                  <a-form-item>
                    <div class="flex-cen" style="width:100%">
                      <a-button class="m-r-20" @click="cancel">取消</a-button>
                      <a-button :disabled="disabled" type="primary" html-type="submit">
                        确定
                      </a-button>
                    </div>
                  </a-form-item>
                </a-form>
              </a-modal>
            </div>
          </div>
        </div>
      </a-layout-header>
      <a-layout>
        <a-layout-sider width="1.0417rem" v-model:collapsed="collapsed" collapsedWidth="45px">
          <a-menu mode="inline" :openKeys="openKeys" v-model:selectedKeys="selectedKeys" @openChange="onOpenChange"
            @click="changeMenu">
            <template v-for="item in menuData">
              <a-menu-item v-if="!item.child" :key="item.path" :path="item.path">
                <template #icon>
                  <div class="lsm-icon" :class="['lsm-icon' + item.id]"></div>
                </template>
                {{ item.name }}
              </a-menu-item>
              <a-sub-menu v-else :key="item.id + ''">
                <template #icon>
                  <div class="lsm-icon" :class="['lsm-icon' + item.id]"></div>
                </template>
                <template #title>{{ item.name }}</template>
                <a-menu-item v-for="li in item.child" :key="li.path" :path="li.path">
                  {{ li.name }}
                </a-menu-item>
              </a-sub-menu>
            </template>
          </a-menu>
        </a-layout-sider>
        <!-- 页面主要内容展示区域 -->
        <a-layout-content>
            <router-view />
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script setup>
// @ is an alias to /src
import { reactive, toRefs, ref, onMounted, createVNode } from "vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { useRouter } from "vue-router";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import { menu } from "@/configData/homeView"; // 侧边菜单
import { menuOther } from "@/configData/homeViewOther"; // 侧边菜单

import { menuDealer } from "@/configData/homeDealer"; // 经销商侧边菜单
import defaultAvatar from "@/assets/images/avatar.png";
import { BellFilled } from '@ant-design/icons-vue';
import { LockOutlined } from '@ant-design/icons-vue';
import { message, Modal } from 'ant-design-vue';
import { useStore } from "vuex";
import { messageApiUnRead } from '@/api/messageApi/messageApi'
import { info, passwordApi } from '@/api/publicApi/publicApi'

const store = useStore()
const router = useRouter();
const formRef = ref()
const personal = () => {// 跳转到个人信息
  router.push('/personalInfo')
}
let getTimeState = () => {
  // 获取当前时间
  let timeNow = new Date();
  // 获取当前小时
  let hours = timeNow.getHours();
  // 设置默认文字
  let text = ``;
  // 判断当前时间段
  if (hours >= 0 && hours <= 10) {
    text = `早上好`;
  } else if (hours > 10 && hours <= 14) {
    text = `中午好`;
  } else if (hours > 14 && hours <= 18) {
    text = `下午好`;
  } else if (hours > 18 && hours <= 24) {
    text = `晚上好`;
  }
  // 返回当前时间段对应的状态
  return text;
}
const time = ref(getTimeState())
const name = ref('')
const id = ref()
const showPot = ref(false)
const arr = ref([])
info().then(res => {
  // console.log('res', res);
  name.value = res?.data?.name
  id.value = res?.data?.id
  store.commit('setDealerUserInfo', res?.data?.code)
  arr.value = []
  res.data.roles.forEach(item=>{
    arr.value.push(item.name)
  })
  store.commit('setAreaCode',res.data.areaCodes)
  store.commit('setAuthority', arr.value)
  if (store.state.role == '2') {
  menuData.value = menuDealer
} else {
  if(!arr.value.includes('管理员')){
    menuData.value = menuOther
  }else{
    menuData.value = menu
  }
}
if (store.state.token && store.state.role == 1 && arr.value.includes('管理员')) {
    messageApiUnRead({ tag: '11' }).then(res => {
      if (Number(res?.data) > 0) {
        showPot.value = true
      }
    }).catch()
  }
}).catch()
const toMessage = () => {
  router.push('/feedbackView')
}
const quit = () => {
  Modal.confirm({
    title: '确定要退出吗?',
    icon: createVNode(ExclamationCircleOutlined),
    // content: 'Some descriptions',
    okText: '确定',
    okType: 'danger',
    cancelText: '取消',
    onOk() {
      store.commit('setToken', '')
      store.commit('setAuthority', '')
      router.push('/login')
    },
    onCancel() {
    },
  });

}
const visible = ref(false)
const changePass = () => { // 修改密码弹窗
  visible.value = true
}
const onFinish = values => { // 修改密码
  const params = {
    id: String(id.value),
    password: values.oldPassword,
    newPassword: values.checkNewPassword
  }
  passwordApi(params).then(() => {
    message.success('密码修改成功')
    store.commit('setToken', '')
    router.push('/login')
  }).catch(err => {
    message.error(err)
  })
  visible.value = false
  formState.value = {}
};
const cancel = () => {
  visible.value = false
  formState.value = {}
}

const formState = ref({
  oldPassword: '',
  newPassword: '',
  checkNewPassword: ''
})

// 表单校验
let validatePass = async (_rule, value) => {
  if (formState.value.oldPassword == '') {
    formState.value.newPassword = ''
    return Promise.reject("请先输入原密码！");
  } else {
    if (value === '') {
      return Promise.reject('请输入密码！');
    } else {
      if (formState.value.oldPassword !== value) {
        return Promise.resolve();
      } else {
        return Promise.reject("两次密码不能一致!");
      }
    }
  }
};
let validatePass2 = async (_rule, value) => {
  if (formState.value.newPassword === '') {
    formState.value.checkNewPassword = ''
    return Promise.reject("请先输入新密码再验证！");
  } else {
    if (value === '') {
      return Promise.reject('请再次输入密码');
    } else if (value !== formState.value.newPassword) {
      return Promise.reject("两次密码不匹配!");
    } else {
      return Promise.resolve();
    }
  }
};
let validatePass3 = async (_rule, value) => {
  if (value === '') {
    return Promise.reject('请输入原密码');
  } else {
    return Promise.resolve();
  }
};
const rules = {
  newPassword: [{
    required: true,
    validator: validatePass,
    trigger: 'change',
  }],
  checkNewPassword: [{
    required: true,
    validator: validatePass2,
    trigger: 'change',
  }],
  oldPassword: [{
    required: true,
    validator: validatePass3,
    trigger: 'change',
  }],
};
const menuData = ref([])
if (store.state.role == '2') {
  menuData.value = menuDealer
} else {
  if(!arr.value.includes('管理员')){
    menuData.value = menuOther
  }else{
    menuData.value = menu
  }
}
const menuChildIds = []; // 子菜单id合集
let menuList = [] // 所有菜单合集（平铺菜单）
menuData.value.forEach((item) => {
  if (item.child) {
    menuChildIds.push(item.id);
    item.child.forEach(li => {
      li.parentId = item.id
      return item
    })
    menuList = [...menuList, ...item.child]
  }
  else
    menuList.push(item)
});
const selectedKeys = ref([menuData.value[0].path])
const state = reactive({
  rootSubmenuKeys: menuChildIds,
  openKeys: [],
  collapsed: false,
});

onMounted(() => {
  const curPath = router?.currentRoute?.value?.path
  const curItem = menuList.filter(item => item.path == curPath)[0]
  if (curItem) {
    selectedKeys.value = [curItem?.path]
    state.openKeys = [curItem.parentId || curItem.id]
  }

})
const { openKeys, collapsed } = toRefs(state);
// 根据屏幕宽度设置侧边栏展示状态
const setMenuSty = () => {
  const width = document.documentElement.clientWidth;
  if (!state.collapsed && width < 1400) {
    state.collapsed = true;
  } else if (state.collapsed && width >= 1400) {
    state.collapsed = false;
  }
};

// 监听页面宽度变化，设置左侧菜单样式
setMenuSty();
onresize = () => {
  setMenuSty();
};

// 处理左侧菜单简洁聚焦（只展开选中项）
const onOpenChange = (openKeys) => {
  const latestOpenKey = openKeys.find(
    (key) => state.openKeys.indexOf(key) === -1
  );
  if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
    state.openKeys = openKeys;
  } else {
    state.openKeys = latestOpenKey ? [latestOpenKey] : [];
  }
};

const changeMenu = ({ item }) => {
  const { path } = item;
  if (path) router.push(path);
};



</script>

<style lang="scss">
.home-page {
  height: 100%;
}

.h-cont {
  min-height: 100% !important;
}

// header
#index-view .ant-layout-header {
  height: 0.3125rem !important;
  display: flex;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  padding: 0 0.1563rem;

  .logo {
    width: 200px;
    height: 0.1875rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .hch-icon {
    width: 0.1875rem;
    height: 0.1875rem;

    img {
      width: 100%;
      height: 100%;

    }
  }

  .hch-title {
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    line-height: 22px;
    margin-left: 9px;
  }

  .hchr-ul {
    margin-right: 0.3542rem;

    &>div {
      margin-right: 0.3125rem;
    }
  }

  .hchr-icon {
    width: 18px;
    height: 18px;
    color: #333;
  }

  .el-dropdown {
    color: #ffffff;
  }
}

// 侧边栏
#index-view .ant-layout-sider {
  color: #333;
  background: #fff;

  .lsm-icon {
    width: 20px;
    height: 20px;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      display: block;
      width: 525px;
      height: 88px;
      background: rgba(255, 255, 0, 0) url(@/assets/images/icon-menu.png) no-repeat top left / 100% 100%;
      position: absolute;
      top: -17px;
    }
  }

  .lsm-icon1::after {
    left: -37px;
  }

  .lsm-icon2::after {
    left: -91px;
  }

  .lsm-icon3::after {
    left: -145px;
  }

  .lsm-icon4::after {
    left: -197px;
  }

  .lsm-icon6::after {
    left: -251px;
  }

  .lsm-icon5::after {
    left: -305px;
  }

  .lsm-icon7::after {
    left: -359px;
  }

  .lsm-icon8::after {
    left: -413px;
  }

  .lsm-icon9::after {
    left: -468px;
  }

  // ant-menu-submenu-open 子菜单展开class
  // ant-menu-submenu-selected 子菜单内有选中
  // ant-menu-submenu-active 子菜单hover

  .ant-menu-submenu-selected .lsm-icon::after,
  .ant-menu-submenu-active .lsm-icon::after,
  .ant-menu-item-active .lsm-icon::after,
  .ant-menu-item-selected .lsm-icon::after {
    top: -51px;
  }

  .ant-menu {
    font-size: 14px;
    color: #666666;
    line-height: 20px;
  }

  .ant-menu-sub {
    color: #999999;
  }

  // 选中效果
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #f7faff;
    font-weight: bold;
  }

  // 去掉右侧边框
  .ant-menu-inline {
    border: none;
  }

  .ant-menu-inline .ant-menu-item-selected::after {
    border-right: 0.016rem solid $mainColor;
    height: 0.125rem;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    right: auto;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: transparent;
  }
}

// 主要内容区域
#index-view .ant-layout-content {
  min-height: 120px;
  background: #f3f7ff;
  padding: 0.0521rem;
}
</style>
