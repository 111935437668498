// import {
//     ref,
//     watch
// } from 'vue'
// import store from '../store/index'

const admin = [{
        name: "系统首页",
        id: "1",
        path: "/"
    },
    {
        name: "终端管理",
        id: "2",
        child: [{
                name: "终端会员",
                id: "2-1",
                path: "/terminalMember"
            },
            {
                name: "经销商",
                id: "2-2",
                path: '/distributorView',
            }
           
        ],
    },
    {
        name: "业务管理",
        id: "3",
        child: [{
                name: "流向列表",
                id: "3-1",
                path: '/floatList'
            },
            {
                name: "积分记录",
                id: "3-2",
                path: '/pointsRecord'
            },
            {
                name: "优惠券列表",
                id: "3-3",
                path: '/couponList'
            },
            {
                name: "营销活动管理",
                id: "3-4",
                path: '/marketingManage',
            },
            {
                name: "积分王",
                path: '/pointsKing',
                id: "3-5",
            },
            {
                name: "成长树",
                path: '/growTree',
                id: "3-6",
            },
            // {
            //     name: "商品对码列表",
            //     id: "3-7",
            //     path: '/goodsList',
            // },
            {
                name: "会员对码列表",
                id: "3-8",
                path: '/vipList',
            },
        ],
    },
    {
        name: "内容管理",
        id: "4",
        child: [{
                name: "产品介绍",
                id: "4-1",
                path: '/productIntroduction',
            },
            {
                name: "学术推广",
                path: '/academicPromotion',
                id: "4-2",
            },
            {
                name: "发现",
                path: '/discoverView',
                id: "4-3",
            },
            {
                name: "热卖单品",
                path: '/hotItem',
                id: "4-4",
            },
            {
                name: "积分规则",
                path: '/recordRule',
                id: "4-5",
            },
            {
                name: "会员等级说明",
                path: '/memberLevelDescription',
                id: "4-6",
            },
            {
                name: "意见反馈",
                path: '/feedbackView',
                id: "4-6",
            },
        ],
    },
    {
        name: "系统配置",
        id: "5",
        child: [{
                name: "账号列表",
                id: "5-1",
                path: '/accountList'
            },
            {
                name: "客服配置",
                id: "5-2",
                path: '/customerService'
            },
            {
                name: "积分配置",
                id: "5-3",
                path: '/pointsRuleConfiguration'
            },
            {
                name: "售价配置",
                id: "5-4",
                path: '/sellingPriceConfiguration'
            },
            {
                name: "品种品规配置",
                id: "5-5",
                path: '/varietyConfiguration'
            },
            {
                name: "基础配置",
                id: "5-6",
                path: '/basicConfiguration'
            },
            {
                name: "推广渠道列表",
                id: "5-7",
                path: '/extensionList'
            }
        ],
    },
    {
        name: "积分商城",
        id: "9",
        child: [{
                name: "商城首页",
                id: "9-1",
                path: '/mall',
            },
            {
                name: "商品管理",
                id: "9-2",
                path: '/mallShopList',
            },
            {
                name: '商品组管理',
                id: "9-3",
                path: '/mallShopGroup',
            },
            {
                name: '商品分类',
                id: "9-4",
                path: '/mallCategory',
            },
            {
                name: '商品订单',
                id: "9-5",
                path: '/mallOrder',
            },
            //Membership level
            {
                name: '积分会员列表',
                id: "9-6",
                path: '/memberLevel',
            },
            {
                name: '终端会员等级',
                id: "9-7",
                path: '/memberRank',
            },
            //membershipLevel
            {
                name: '经销商会员等级',
                id: "9-8",
                path: '/membershipLevel',
            },
            // {
            //     name: '销售明细',
            //     id: "9-7",
            //     path: '/mallSaleDetail',
            // },
            {
                name: '认证礼商品设置',
                id: "9-9",
                path: '/productSettings',
            },
        ],
    },
]


// const menuData = ref([])

// watch(() => store.state.role, (val) => {
//     console.log('-----val', val);
//     if (val == '1') {
//         console.log('-----admin', val);
//         menuData.value = []
//         menuData.value = admin
//     } else {
//         console.log('-----dealer', val);
//         menuData.value = []
//         menuData.value = dealer
//     }
// }, {
//     immediate: true
// })
// console.log('menuData.value-----',menuData.value);
export const menu = [
    ...admin
]