import http from "@/utils/http";
//
/**
 *  公共接口
 */
/** 获取行政区域结构 **/
export function getProvince(params) {
  return http.get(`/common/v1/areas`, params);
}
// 图片上传
export function getUploadImg(params) {
  return http.post(`/mini/v1/login/oss`, params);
}
// 积分商城图片上传
export function getUploadMallImg(params) {
  return http.post(`/mini/v1/login/manyoss`, params);
}

/**自动识别 */
export function uploadAuto(params) {
  return http.post(`/mini/v1/login/ocr`, params);
}
// 获取账户详情
export function info(params) {
  return http.get(`/v1/web/users/profile`, params);
}

// 修改密码
export function passwordApi(params) {
  return http.post(`/v1/web/users/update-password`, params);
}

/** 获取错误码 */
export function getErrCode(params) {
  return http.get(`/common/v1/error-code`, params);
}
// GET
// /
/** 获取列表crm */
export function getCrm(params) {
  return http.get(`/v1/admin/crm-user/terminal`, params);
}

/** 获取经销商销售区域 */
export function getDealerSaleArea(params) {
  return http.get(`/mini/v1/dealer-area/by-code`, params);
}

